import {
  Container,
  Drawer,
  Typography,
  useTheme
} from "@mui/material";
import AddActivity from "components/AddActivity";
import AddInfo from "components/AddInfo";
import AddSchoolVisit from "components/AddSchoolVisit";
import { useAppContext } from "contexts";
import { useUniversities } from "hooks";
import { useEffect, useState } from "react";

const EditStudentDrawer = ({ open, setOpenEditStudentDrawer }) => {
  const theme = useTheme();
  const { snackBarOpen } = useAppContext();
  const { sendNotification, sendMail } = useAppContext();
  const { user } = useAppContext();
  const drawerData = open;
  // console.log(drawerData);
  // console.log(open);
  const [bookedValue, setBookedValue] = useState(false);
  const { universities } = useUniversities();


  // console.log(Universities);



  useEffect(() => {
    if (open?.id) {
      setBookedValue(open?.isBooked);
    }
    return () => { };
  }, [open]);
  console.log(drawerData, "drawerData")

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpenEditStudentDrawer(false)}
      >
        <Container
          className="!90vw !mt-12vh  "
          sx={{
            width: "25vw",
            // marginTop: "10vh",
            // [theme.breakpoints.up("sm")]: {
            //   maxWidth: "50vw",
            // },
            // [theme.breakpoints.up("md")]: {
            //   maxWidth: "80vw",
            // },
            // [theme.breakpoints.up("lg")]: {
            //   maxWidth: "70vw",
            // },
          }}
        >
          <Typography align="center" color="text.primary" variant="h5">
            Update Fair
          </Typography>
          {drawerData?.fairType === "SCHOOL VISIT" ? (
            <AddSchoolVisit
              open={open}
              setOpenDrawer={setOpenEditStudentDrawer}
            />
          ) : drawerData?.fairType === "ACTIVITY" ? (
            <AddActivity open={open} setOpenDrawer={setOpenEditStudentDrawer} />
          ) : drawerData?.fairType === "INFO" ? (
            <AddInfo open={open} setOpenDrawer={setOpenEditStudentDrawer} />
          ) : (
            ""
          )}
          {/* <Formik
            enableReinitialize
            initialValues={
              drawerData?.displayName
                ? {
                    displayName: drawerData?.displayName,
                    city: drawerData?.city,
                    schoolName: drawerData?.schoolName,
                    date: drawerData?.date,
                    time: drawerData?.time,
                    endTime: drawerData?.endTime,
                    credits: drawerData?.credits,
                    link: drawerData?.link,
                    notes: drawerData?.notes,
                    studentCount: drawerData?.studentCount,
                    isBooked: drawerData?.isBooked,
                  }
                : initialValues
            }
            validationSchema={Yup.object(validationSchema)}
            onSubmit={handleSend}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={0.5} justifyContent="center">
                  {addFairSchema?.map((inputItem) => (
                    <Grid
                      item
                      key={inputItem.key}
                      xs={12}
                      sm={12}
                      md={inputItem?.name === "univerNotes" ? 12 : 6}
                      lg={inputItem?.name === "univerNotes" ? 12 : 6}
                    >
                      <Field name={inputItem.name} key={inputItem.key}>
                        {(props) => {
                          if (inputItem.type === "select") {
                            return (
                              <FormControl
                                required
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                error={Boolean(
                                  props.meta.touched && props.meta.error
                                )}
                              >
                                <InputLabel id={`label-${inputItem.name}`}>
                                  {inputItem.label}
                                </InputLabel>
                                <Select
                                  labelId={`label-${inputItem.name}`}
                                  id={inputItem.name}
                                  label={inputItem.label}
                                  {...props.field}
                                >
                                  {inputItem.options.map((option) => (
                                    <MenuItem
                                      value={option.value}
                                      key={option.key}
                                    >
                                      {option?.phone && (
                                        <img
                                          loading="lazy"
                                          width="20"
                                          src={`https://flagcdn.com/w20/${option.key.toLowerCase()}.png`}
                                          srcSet={`https://flagcdn.com/w40/${option.key.toLowerCase()}.png 2x`}
                                          alt=""
                                          style={{ margin: "0 1vw" }}
                                        />
                                      )}

                                      {option?.phone ? (
                                        <>{`${option.value} (${option.key}) +${option.phone} `}</>
                                      ) : (
                                        option.value
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {props.meta.touched && props.meta.error}
                                </FormHelperText>
                              </FormControl>
                            );
                          }
                          return (
                            <div>
                              <TextField
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                label={inputItem.label}
                                type={inputItem.type}
                                multiline={inputItem?.multiline}
                                rows={inputItem?.rows}
                                error={Boolean(
                                  props.meta.touched && props.meta.error
                                )}
                                helperText={
                                  props.meta.touched && props.meta.error
                                }
                                {...props.field}
                                InputLabelProps={{ shrink: true }}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                  ))}
                  <Grid item lg={6} md={6}>
                    <FormControlLabel
                      sx={{ m: 2 }}
                      control={
                        <Checkbox
                          name="isBooked"
                          checked={bookedValue}
                          onChange={(e) => {
                            setBookedValue(open?.isBooked ? false : true);
                            formik?.setFieldValue(
                              "isBooked",
                              open?.isBooked ? false : true
                            );
                          }}
                        />
                      }
                      label="Fully Booked"
                    />
                  </Grid>
                </Grid>
                <div>
                  <div style={{ marginTop: "4px", marginBottom: "2vh" }}>
                    <LoadingButton
                      className="mt-1vh gradient"
                      variant="contained"
                      sx={{ color: "snow" }}
                      type="submit"
                      fullWidth
                      disabled={formik.isSubmitting || !formik.isValid}
                      loading={formik.isSubmitting}
                      loadingPosition="start"
                      startIcon={<Done sx={{ color: "snow" }} />}
                    >
                      Save
                    </LoadingButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik> */}
        </Container>
      </Drawer>
    </>
  );
};

export default EditStudentDrawer;
